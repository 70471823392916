import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import CloseIcon from '@mui/icons-material/Close';
import wait from "./images/wait.svg";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import site from "./images/site.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginIcon from '@mui/icons-material/Login';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProgressBar from './ProgressBar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import stripe from "./images/stripe.svg";
import DoneIcon from '@mui/icons-material/Done';
import {Helmet} from 'react-helmet';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import expired from "./images/expired.jpg";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from '@mui/icons-material/Code';

const Documentation = () => {

  const user_info = JSON.parse(localStorage.getItem('user'));
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

const changeColor = (color) => {
	//console.log(color);
	if(color === '#1dd1a1'){
			setStyle('bodyColor1');
	}
	if(color === '#ff6b6b'){
			setStyle('bodyColor2');
	}
	if(color === '#2e86de'){
			setStyle('bodyColor3');
	}
	if(color === '#f368e0'){
			setStyle('bodyColor4');
	}
	if(color === '#ff9f43'){
			setStyle('bodyColor5');
	}
}

const [toggle, setToggle] = useState('switch to register');
const navigate = useNavigate();
const [style, setStyle] = useState("bodyColor1");
const [show, setShow] = useState(false);
const [user, setUser] = useState({
		email: "",
		password: "",
		cnf_password: ""
});

const [login, setLogin] = useState({
		submit: false,
		status : false,
		invalid: false,
		verification: false,
		account : '',
		userID : 0,
		user_email: '',
		user_exist : false,
		user_exist_text : 'Email already exists' 
});

const [forgotPass, setForgotPass] = useState(false);
const [resetPass, setResetPass] = useState(false);
const [forgot_pass_email, setForgotPassEmail] = useState('');
const [forgot_pass_submit, setForgotPassSubmit] = useState(false);
const [resetPassOTP, setResetPassOTP] = useState('');
const [resetPassNew, setResetPassNew] = useState('');
const [resetPassConfirm, setResetPassConfirm] = useState('');
const [reset_pass_submit, setResetPassSubmit] = useState(false);
const [resetPassSuccess, setResetPassSuccess] = useState(false);
const [resetPassFailure, setResetPassFailure] = useState(false);

const toggleMenu = () => {
		let navigation = document.querySelector('.navigation');
			let toggle = document.querySelector('.toggle');
			navigation.classList.toggle('active');
 }

 const handleSubmit = (e) => {
		e.preventDefault();
		
		//console.log(user);
		if(user.email === '' || user.password === ''){
			alert('Please enter email and password');
            return;
		}
		if(user.password !== user.cnf_password){
			alert('Password and confirm password not matches');
            return;
		}
		
		setLogin({...login, submit : true});

		fetch('https://oneclickhuman.com/oneclickprice/register', {
			mode:'cors', 
			method: 'POST',
			body: JSON.stringify({
				'email' : user.email,
				'password' : user.password
			}),
			headers: {
				 'Content-type': 'application/json; charset=UTF-8',
			}
		}).then(res => res.json())
			.then((json) => {
				 //console.log(json);
				 if(json.id === 0){
                     setLogin({...login, user_exist : true}); 
                     setToggle('switch to login'); 
				 }else{
				    setLogin({...login, account : 'pending', userID : json.id, user_email: json.user_email});	
				 }
		});
 };

  const handleSubmitLogin = (e) => {
		e.preventDefault();
		
		//console.log(user);
		if(user.email === '' && user.password === ''){
			alert('Please enter email and password');
            return;
		}
		setLogin({...login, user_exist : false}); 
		setLogin({...login, submit : true, invalid: false, verification: false});

		fetch('https://oneclickhuman.com/oneclickprice/login', {
			mode:'cors', 
			method: 'POST',
			body: JSON.stringify({
				'email' : user.email,
				'password' : user.password
			}),
			headers: {
				 'Content-type': 'application/json; charset=UTF-8',
			}
		}).then(res => res.json())
			.then((json) => {
				// console.log(json);
				 if(json.login === 'success'){
						 setLogin({...login, account : 'active', userID : json.id, user_email: json.user_email, invalid: false});
						 localStorage.setItem('user', JSON.stringify({
						  	account : 'active',
						  	userID: json.id,
						  	user_email: json.user_email
						  }));
						 setTimeout(() => {
							setShow(false);
              window.location.reload('/account'); 
						 }, 500);

				 }else if(json.login === 'on-verification'){
				 	setLogin({...login, verification : true});
				 }else{
           setLogin({...login, invalid : true});
				 }
		});
 };

const signOut = (e) => {
  e.preventDefault(); 
  setLogin({...login, submit: false, status : false, invalid: false, account: '', userID: 0});
  localStorage.removeItem('user');
  var toast = document.getElementById("toast-logout");
    var progress = document.getElementById("progress-logout");
    toast.classList.add("active");
    progress.classList.add("active");
    setTimeout(() =>{
       toast.classList.remove("active");
    }, 5000);
    setTimeout(() =>{
       progress.classList.remove("active");
    }, 5300);
}
const forgotPassword = () => {
   setForgotPass(true);
   setToggle("");
}
const sendOTP = (e) => {
   e.preventDefault();

   if(forgot_pass_email === ''){
       alert('Please enter your email');
       return;
   }
   setForgotPassSubmit(true);
   fetch('https://oneclickhuman.com/oneclickprice/forgot_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setForgotPassSubmit(false);
                   setForgotPass(false);
                   setResetPass(true);
              }
    });
}
const handleResetPassword = (e) => {
   e.preventDefault();
   if(resetPassOTP === ''){
       alert('Please Enter OTP');
       return;
   }  
   if(resetPassNew === ''){
       alert('Please Enter new password');
       return;
   }
   if(resetPassConfirm === ''){
       alert('Please Enter confirm password');
       return;
   }
   if(resetPassNew !== resetPassConfirm){
       alert('New Password and Confirm Password does not match!');
       return;
   }

   setResetPassSubmit(true);
   setResetPassFailure(false);

      fetch('https://oneclickhuman.com/oneclickprice/reset_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email,
        'new_password' : resetPassNew,
        'otp' : resetPassOTP
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setResetPassSuccess(true);
                   setResetPassSubmit(false);
              }else{
                 setResetPassSubmit(false);
                 setResetPassFailure(true);
              }
    });  
}
const userClick = (e) => {
	e.preventDefault();
   if(login.account !== 'active'){
		  setShow(true);
	}else{
    navigate("/account");
  }
}
const resendEmailVerification = () => {
   //console.log(login.userID);
   fetch('https://oneclickhuman.com/oneclickprice/resend_email_verification', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : login.user_email,
        'user_id' : login.userID,
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         //console.log(json);
         if(json.status === 'success'){
              alert('Verification Email is resent.');
         }
      });
}

useEffect(() => {
     if(login.account === 'pending'){
       const sse = new EventSource(`https://oneclickhuman.com/oneclickprice/sse/${login.userID}`);
       sse.onmessage = function (event) {
        //console.log(event.data);
        if(event.data == 1){
          setLogin({...login, account : 'active'});
          localStorage.setItem('user', JSON.stringify({
            account : 'active',
            userID: login.userID,
            user_email: login.user_email
            }));
            setTimeout(() => {
            setShow(false);
          }, 2000);
               
          this.close();
        }    
       };
   }
}, [login])

const [account_status, setAccountStatus] = useState({
   monthly_plan: 0,
   credits_availbe: 0,
   onetime_plan: 0,
   onetime_credit: 0,
   daily_quota: 0,
   subscrption_status: 0,
   subscription_amount: 0,
   onetime_amount: 0,
   lifetime_plan: 0,
   lifetime_credits: 0,
   max_lifetime_used: 0,
   currency: '',
   subscription_renewal_date: 0,
   current_date: null,
   is_renewal_date_crossed: 0,
});

const [subscription_expiry, setSubscriptionExpiry] = useState(0);
const [has_subscription, setHaveSubscription] = useState(false);
const [reset_hours, setResetHours] = useState(0);


useEffect(() => {
 //console.log(has_subscription);
   if (user_info) {
     setLogin({...login, account: user_info.account, userID: user_info.userID, user_email: user_info.user_email});
     
     fetch('https://oneclickhuman.com/oneclickprice/checkquota', {
      mode:'cors', 
      method: 'POST',
        body: JSON.stringify({
        'user_id' : user_info.userID,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
     }).then(res => res.json())
       .then((json) => {
          //console.log(json);
          if(json.subscrption_status === 1){
              setHaveSubscription(true);
              setPlan({...plan, monthly: false, onetime: true});
          }

          var next_date = json.subscription_renewal_date;
          var next_arr = next_date.split('T');
          var next_date_real = next_arr[0];
          var is_renewal_date_crossed = 0;
          if (new Date() > new Date(next_date_real)){
              is_renewal_date_crossed = 1;
          }else{
             is_renewal_date_crossed = 0;
          }
          setAccountStatus({...account_status, monthly_plan: json.monthly_plan, credits_availbe: json.credits_availbe, onetime_plan: json.onetime_plan, onetime_credit: json.onetime_credit, lifetime_plan: json.lifetime_plan, lifetime_credits: json.lifetime_credits, max_lifetime_used: json.max_lifetime_used, currency: json.currency, daily_quota: json.quota, subscrption_status: json.subscrption_status, subscription_amount: json.subscription_amount, onetime_amount: json.onetime_amount, subscription_renewal_date: next_date_real, current_date: json.current_date, is_renewal_date_crossed: is_renewal_date_crossed});
          const date1 = new Date();
          const date2 = new Date(next_date_real);
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          //console.log(diffDays + " days");
          setSubscriptionExpiry(diffDays);

          if(json.quota === 0){
             //console.log(json.current_date);
             var timeArr = json.current_date.split("-");
             var newd = parseInt(timeArr[2]) + 1;
             var newtime = timeArr[0] + '-' + timeArr[1] + '-' + newd;

             // Set the date we're counting down to
             var countDownDate = new Date(`${newtime} 00:00:00`).getTime();
             // Get today's date and time
             var dt = new Date();
             var edt = dt.toLocaleString('en-US', {timeZone: 'America/New_York'});
             var now = new Date(edt).getTime();

             var distance = countDownDate - now;
             // Time calculations for hours
             var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
             hours = ("0" + hours).slice(-2);
             setResetHours(hours);
         }
     });

   }

}, [1]);


const [promodal, setProModal] = useState(false);
const [secondPro, setSecondPro] = useState(false);
const [countdown, setCountdown] = useState(""); 
const [showback, setShowBack] = useState(true);
const [plan, setPlan] = useState({
    monthly: true,
    onetime: false
});
const [subscription, setSubscription] = useState('7');
const [onetime_purchase, setOneTimePurchase] = useState('3');

const [products, setProducts] = useState([]);
const [price_show, setPrice] = useState(0);
const [discount_price_show, setDiscountPrice] = useState(0);
const [price_show_onetime, setPriceOnetime] = useState(0);

useEffect(() => {
   fetch('https://oneclickhuman.com/oneclickprice/get_prices', {
      method: 'GET'
    }).then(res => res.json())
      .then((json) => {
        //console.log(json.products);
        setProducts(json.products);
        let filterProdSubscrption = json.products.filter((product) => product.currency === 'usd' && product.is_subscription === 1);
        setSubscription(filterProdSubscrption[0].price_id);
        let filterProdOnetime = json.products.filter((product) => product.currency === 'usd' && product.is_subscription === 0);
        setOneTimePurchase(filterProdOnetime[0].price_id);
    });

}, [1]);

const [is_india, setIsIndia] = useState(false);

const fetchGeoLocation = () => { 
 fetch('https://geolocation-db.com/json/62d8d690-e7b8-11ed-8c81-cbb990727ab5')
   .then( res => res.json())
   .then(response => {
      //console.log("Country is : ", response);
      if(response.country_code === 'IN'){
          setIsIndia(true);
          let filterProdSubscrption = products.filter((product) => product.currency === 'inr' && product.is_subscription === 1);
          setSubscription(filterProdSubscrption[0].price_id);
          let filterProdOnetime = products.filter((product) => product.currency === 'inr' && product.is_subscription === 0);
          setOneTimePurchase(filterProdOnetime[0].price_id);
      }
  })
  .catch((data, status) => {
    //console.log('Request failed:', data);
  });
}

useEffect(() => {
  try{
    fetchGeoLocation();
  }catch{
     fetchGeoLocation();
  }
}, [products]);

useEffect(() => {
  let filterProd = products.filter((product) => product.price_id === subscription);
 // console.log(filterProd.length);
  if(filterProd.length !== 0){
      setPrice(filterProd[0].amount);
  }
}, [subscription]);

useEffect(() => {
  let filterProd = products.filter((product) => product.price_id === onetime_purchase);
  //console.log(filterProd.length);
  if(filterProd.length !== 0){
      setPriceOnetime(filterProd[0].amount);
  }
}, [onetime_purchase]);

const [promocode, setPromoCode] = useState('OFF20');
const [promo_status, setPromoStatus] = useState(true);

const [coupons, setCoupons] = useState([]);
useEffect(() => {
   fetch('https://oneclickhuman.com/oneclickprice/get_coupon', {
      method: 'GET'
    }).then(res => res.json())
      .then((json) => {
        //console.log(json.products);
        setCoupons(json.coupons);
    });

}, [1]);

const [discount_applied, setDiscountApplied] = useState({value: 20, status: true});
const applyCoupon = () => {
  let filterCoupon = coupons.filter((coupon) => coupon.code === promocode);
     if(filterCoupon.length !== 0){
         setDiscountApplied({...discount_applied, value: filterCoupon[0].discount, status: true });
     }else{
       alert('Invalid Coupon');
     }
}



 return(
       <>
         <Helmet>
           <title>Documentation - OneClickHuman</title>
           <meta name="description" content="" />
         </Helmet>

            <div className="wrapper">
        <div className="header-wrapper">
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand gap-3">
                        <div className="topbar-logo-header d-none d-lg-flex">
                            <div className="">
                                <h4 className="logo-text">OneClickHuman</h4>
                            </div>
                        </div>
                        <div className="mobile-toggle-menu d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"><i className='bx bx-menu'></i></div>

               <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">OneClickHuman</h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/api" >
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>


                  { login.account === 'active' ?
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="user-info">
                                   <p className="user-name mb-0">{login.user_email}</p>  
                                </div>
                            </a>
                            
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={userClick}><i class="bx bx-user fs-5"></i><span>Account</span></a>
                                </li>
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={signOut}><i class="bx bx-log-out-circle"></i><span>Logout</span></a>
                                </li>
                            </ul>
                        </div>
                        :
                        <div className="user-box px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="#" role="button">
                                <div className="user-info">
                                 <button onClick={() => { setShow(true); setToggle('switch to login');}}><LoginIcon/> Login</button>
                                </div>
                            </a>
                        </div>
                    }

                    </nav>
                </div>
            </header>
           </div>


            <div className="page-wrapper">
            <div className="page-content">
                <div className="row">
                    <div className="col-xl-10 mx-auto">
                        <div className="card border-top border-0 border-4 border-white">
                            <div className="card-body p-0">


	 <AccountBox id={ matches === true ? "": "tool-off" }>
        <Heading>
          <MenuBookOutlinedIcon/> <b>OneclickHuman.com Documentation</b>	
          <a href="/">⭪  Go Back to tool</a>
        </Heading>
        <Content>

          <div className="innerAcc">
            <Row>
              <div className="column-inner">
                <h3>🛠️ How to Use OneclickHuman.com Effectively 🛠️</h3>
                <br></br>
                <h4>Method 1: <small className="mysmall">For content that needs minimal edits to preserve its original quality.</small></h4>
                <ol className="my-ol">
                  <li>Use <b>Quality Mode</b> to maintain the structure and quality with minimal content changes.</li>
                  <li>If any sections are flagged as AI-generated, switch to <b>Balanced Mode</b> for these specific parts and reprocess for improved humanization.</li>
                </ol>
                <br></br>
                <h4>Method 2: <small className="mysmall">When aiming for content that you want for your Blog, SEO, guest posts.</small></h4>
                <ol className="my-ol">
                  <li>Process your article initially in <b>Balanced Mode</b> to defeat most AI detection tools and ensure cleanly formatted output.</li>
                  <li>Perform a manual review to tweak any minor issues that may arise, although they are typically scarce.</li>
                </ol>
                <br></br><br></br>
                <hr/>
                <br></br><br></br>
                <h3>🌟 Conversion Modes Explained 🌟</h3>
                <br></br>
                <table>
                  <tr>
                    <th>Conversion Mode</th>
                    <th>Success Rate in Beating AI Detection Tools</th>
                    <th>Quality</th>
                    <th>Output Formatting</th>
                  </tr>
                  <tr>
                    <td>Wild Mode</td>
                    <td>99% (Very High) Will beat all AI detection tools</td>
                    <td>May vary</td>
                    <td>Plain text</td>
                  </tr>
                  <tr>
                    <td>Balanced Mode</td>
                    <td>95% (High)</td>
                    <td>High</td>
                    <td>Maintains Formatting</td>
                  </tr>
                  <tr>
                    <td>Quality Mode</td>
                    <td>80% (Moderate) - A few tools may detect AI</td>
                    <td>Very high</td>
                    <td>Maintains Formatting</td>
                  </tr>
                </table>
                <br></br>
                <h4>1️⃣ Quality Mode:</h4>
                <ul className="my-ul">
                  <li><b>Focus:</b> Preserves original content quality, recommended for academic or research material.</li>
                  <li><b>Detection:</b> Can bypass many tools, but may not be effective against some AI detectors.</li>
                  <li><b>Content:</b> Minimal content alteration</li>
                </ul>
                <br></br>
                <h4>2️⃣ Balanced Mode:</h4>
                <ul className="my-ul">
                  <li><b>Efficiency:</b> Outperforms all standard AI detection tools and has a 95% success rate against AI detection tools.</li>
                  <li><b>Output:</b> Provides well-formatted content without grammatical or structural errors.</li>
                  <li><b>Use Case:</b> Ideal for SEO, Guest posts & other internet articles.</li>
                </ul>
                <br></br>
                <h4>3️⃣ Wild Mode:</h4>
                <ul className="my-ul">
                  <li><b>Reliability</b>: Designed to completely evade AI detection, even by advanced tools.</li>
                  <li><b>Content Quality:</b> Accepts slight grammatical inaccuracies; does not support advanced formatting.</li>
                  <li><b>Suitability:</b> Optimal when concealing AI involvement is crucial, with a trade-off in formatting and minor errors.</li>
                </ul>
                <br></br><br></br>
                <hr/>
                <br></br><br></br>
                <h3>🔥 Expert Tips 🔥</h3>
                <ul className="my-ul">
                  <li><b>Wild Mode Advantages:</b> Choose Wild Mode for the most human-like result, bypassing the toughest detectors at the expense of formatting and with occasional slight grammar imperfections.</li>
                  <li><b>Content Length:</b> For full efficacy, submit articles over 150 words.</li>
                </ul>
                <br></br>
                <p>With these updated practices, OneclickHuman.com ensures your AI-generated content is transformed with precision, tailored to your needs, and safeguarded against the latest detection tools.</p>
              </div>	
            </Row>
          </div>
        </Content>
	 </AccountBox>

                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>


        <div className="overlay toggle-icon"></div>
        <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
        <footer className="page-footer">
            <p className="mb-0">Copyright ©. All right reserved.</p>
        </footer>
   

        <div className="switcher-wrapper">
        <div className="switcher-btn"> <i className='bx bx-cog bx-spin'></i>
        </div>
        <div className="switcher-body">
            <div className="d-flex align-items-center">
                <h5 className="mb-0 text-uppercase" style={{fontSize: '1rem'}}>Change Background</h5>
                <button type="button" className="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr/>
            <p className="mb-0">Gaussian Texture</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme1"></li>
                <li id="theme2"></li>
                <li id="theme3"></li>
                <li id="theme4"></li>
                <li id="theme5"></li>
                <li id="theme6"></li>
              </ul>
               <hr/>
              <p className="mb-0">Gradient Background</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme7"></li>
                <li id="theme8"></li>
                <li id="theme9"></li>
                <li id="theme10"></li>
                <li id="theme11"></li>
                <li id="theme12"></li>
                <li id="theme13"></li>
                <li id="theme14"></li>
                <li id="theme15"></li>
              </ul>
        </div>
    </div>

{ show === true ?
     <div id="popup">
        <div id="login-form">
          { toggle === 'switch to register' ?
            <form onSubmit={handleSubmit}>
               <h3 style={{marginBottom: '7px'}}>Create New Account!</h3>
               <p className="already">Already have an account? <span onClick={() => setToggle('switch to login')}>Login in</span></p>
                  <span className="closeLogin" onClick={() => {setLogin({...login, submit: false, status : false, invalid: false, verification: false, account : '', userID : 0, user_email: '', user_exist : false, user_exist_text : 'Email already exists'}); setShow(false); }}><CloseIcon/></span> 
              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                 <input type="password" placeholder="Password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>

              <div className="inputBox">
                 <input type="password" placeholder="Confirm password" onChange={(e) => setUser({ ...user, cnf_password: e.target.value })} />
              </div>

              <button type="submit">SIGN UP</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : ''
              }
              { login.account === 'pending' ?
               <>
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />awaiting email verification....</p>
                 <p id="resend-verification" style={{color: '#fff'}}><span onClick={resendEmailVerification}>Resend email verification</span></p>
                </>
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Account activated</p> 
                : ''
              }
              <div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => setToggle('switch to login')}><LockIcon/> Login</span> 
              </div>
            </form>
            : 
           ''}

               { toggle === 'switch to login' ?
             <form onSubmit={handleSubmitLogin}>
              <h3>Welcome Back!</h3>
                            <span className="closeLogin" onClick={() => setShow(false)}><CloseIcon/></span> 

              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                 <input type="password" placeholder="Enter your password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>
              <div id="forgot-pass">
                <span onClick={forgotPassword}>Forgot Password</span> 
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>LOGIN</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : ''
              }
              { login.user_exist === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>{login.user_exist_text}</p> 
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Login Success</p> 
                : ''
              }
              { login.invalid === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Invalid username or password</p> 
                : ''
              }
              { login.verification === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Email verification is pending</p> 
                : ''
              }
              <div className="form-footer">
                              <span className="left-foot">All fields are required.</span>
                              <span className="right-foot" onClick={() => setToggle('switch to register')}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
            : ''
          }
          { forgotPass === true ?
            <form onSubmit={sendOTP}>
              <h3>Forgot Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>Submit your email id, we will send an otp for resetting new password.</p>
              <span className="closeLogin" onClick={() => {setShow(false);setForgotPass(false)}}><CloseIcon/></span>
              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setForgotPassEmail(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SEND OTP</button>
              { forgot_pass_submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Sending OTP....</p> 
                : '' 
              }
              <div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => {setToggle('switch to register');setForgotPass(false)}}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
                       : ''
          }

          { resetPass === true ?
                       <form onSubmit={handleResetPassword}>
              <h3>Reset Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>We have sent an OTP to your email id, Please enter otp and set new password</p>
                            <span className="closeLogin" onClick={() => {setShow(false);setResetPass(false)}}><CloseIcon/></span>
              <div className="inputBox">
                 <input type="text" placeholder="Enter OTP" onChange={(e) => setResetPassOTP(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="password" placeholder="Enter New Password" onChange={(e) => setResetPassNew(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="text" placeholder="Confirm New Password" onChange={(e) => setResetPassConfirm(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SUBMIT</button>
              { reset_pass_submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : '' 
              }
              { resetPassSuccess === true ?
                <p style={{color: '#5bed61', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>New Password set Successfully, <span style={{cursor: 'pointer', }} onClick={() => {setResetPass(false);setToggle('switch to login'); }}>Login</span></p>  
                : ''  
              }
              { resetPassFailure === true ?
                <p style={{color: 'red', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>Invalid OTP, try again</p>    
                : ''
              }
            </form> 
            : ''
          }
        </div>
     </div>
      : ''
}


</div>

{ matches !== true ?
  <div id="for-mobile">
    <p>Our Mobile experience is still under development, but you can get the best experience by using our desktop version</p>
  </div>
  : ''
}

{ matches === true ?
  <div className="toast" id="toast-logout">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Logged Out</span>
      </div>
    </div>
    <div className="progress" id="progress-logout"></div>
  </div>
    : ''
}
	
 
       </>
  	)
}

export default Documentation;

const Progress = styled.div`
   width:100%;
   margin-bottom: 20px;

   .progress-top {
      display: flex;
      margin-bottom: 5px;

      p {
         width: 100%;
      }
      .progress-label {
         font-size: 14px;
      }
      .progress-value {
        text-align: right;
        font-size: 13px;
      }
    }

    .progress-btm {
      font-size: 12px;
      text-align: right;
      margin-top: 4px;
      color: #787878;
     }
`;

const SmallBtn = styled.div`
   display: flex;
   width:100%;

   button {
   	 position: static !important;
   	 margin-right: 10px;
     width: unset !important;
     height: unset !important;
     padding: 8px 12px;
     font-size: 13px; 
   }
`;

const Row = styled.div`
   width: 100%;
   display: flex;
   margin-bottom: 50px;

   .column-inner {
      width: 100%;
      padding: 0 15px;

      h4 .mysmall {
            font-size: 16px;
            font-weight: 400;
      }

      table {
         border: 1px solid #ddd;
         border-collapse: collapse;
           th, td {
              border: 1px solid #ddd;
              padding: 15px;
           }
      }
      .note {
      	 font-size: 12px;
         background: #e7f4fd;
         color: #0f08ad;
         font-weight: 500;
         line-height: 20px;
         padding: 10px;
      }
   }
   h2 {
    svg {color: #365fa1;}
   }
`;

const Card = styled.div`
   width:100%;
   padding: 20px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 1px 1px 13px 1px #b8b5b5;
   position: relative;
   text-align: left !important;
   margin: 10px;
   margin-bottom: 30px;

   .features {
    font-size: 14px;
    line-height: 27px;
    color: #000;
   }

   .plan-card {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 20px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 50px;
        font-weight: 600;
        vertical-align: text-top;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 145px;
    height: 40px;
    cursor: pointer;

     svg { vertical-align: middle; }
   }
`;

const CardPopup = styled.div`
      width:100%;
   padding: 20px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 1px 1px 13px 1px #b8b5b5;
   position: relative;
   text-align: left !important;
   margin: 10px;

   .features {
    font-size: 14px;
    line-height: 27px;
    color: #000;
   }

   .plan-card {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 20px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 50px;
        font-weight: 600;
        vertical-align: text-top;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 145px;
    height: 40px;
    cursor: pointer;

     svg { vertical-align: middle; }
   }
`;

const Heading = styled.div`
    width: 100%;
    padding: 20px 15px;
    background: rgb(0 0 0 / 12%);
    font-size: 18px;
    position: relative;
    color: #fff;
   svg { 
   vertical-align: sub;
    color: #0f6bb4; }
    a {
      text-decoration: none;
    font-size: 15px;
    padding: 8px 16px;
    background: #0abc8d;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    top: 12px;
    }
`;

const AccountBox = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 545px;
`;

const Content = styled.div`
   width:100%;
   overflow-x: hidden;

   .innerAcc {
   	  width: 103%;
   	  overflow-y: scroll;
   	  height: 450px;
   	   padding: 30px;
   }
`;

