import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import CloseIcon from '@mui/icons-material/Close';
import wait from "./images/wait.svg";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import site from "./images/site.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginIcon from '@mui/icons-material/Login';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneIcon from '@mui/icons-material/Done';
import {Helmet} from 'react-helmet';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import expired from "./images/expired.jpg";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import ProgressBar from './ProgressBar';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from '@mui/icons-material/Code';


const AffiliateTerms = () => {

  const user_info = JSON.parse(localStorage.getItem('user'));
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

const changeColor = (color) => {
  //console.log(color);
  if(color === '#1dd1a1'){
      setStyle('bodyColor1');
  }
  if(color === '#ff6b6b'){
      setStyle('bodyColor2');
  }
  if(color === '#2e86de'){
      setStyle('bodyColor3');
  }
  if(color === '#f368e0'){
      setStyle('bodyColor4');
  }
  if(color === '#ff9f43'){
      setStyle('bodyColor5');
  }
}

const [toggle, setToggle] = useState('switch to register');
const navigate = useNavigate();
const [style, setStyle] = useState("bodyColor1");
const [show, setShow] = useState(false);
const [user, setUser] = useState({
    email: "",
    password: "",
    cnf_password: ""
});

const [login, setLogin] = useState({
    submit: false,
    status : false,
    invalid: false,
    verification: false,
    account : '',
    userID : 0,
    user_email: '',
    user_exist : false,
    user_exist_text : 'Email already exists' 
});

const [forgotPass, setForgotPass] = useState(false);
const [resetPass, setResetPass] = useState(false);
const [forgot_pass_email, setForgotPassEmail] = useState('');
const [forgot_pass_submit, setForgotPassSubmit] = useState(false);
const [resetPassOTP, setResetPassOTP] = useState('');
const [resetPassNew, setResetPassNew] = useState('');
const [resetPassConfirm, setResetPassConfirm] = useState('');
const [reset_pass_submit, setResetPassSubmit] = useState(false);
const [resetPassSuccess, setResetPassSuccess] = useState(false);
const [resetPassFailure, setResetPassFailure] = useState(false);

 const toggleMenu = () => {
    let navigation = document.querySelector('.navigation');
      let toggle = document.querySelector('.toggle');
      navigation.classList.toggle('active');
 }

 const handleSubmit = (e) => {
    e.preventDefault();
    
    //console.log(user);
    if(user.email === '' || user.password === ''){
      alert('Please enter email and password');
            return;
    }
    if(user.password !== user.cnf_password){
      alert('Password and confirm password not matches');
            return;
    }
    
    setLogin({...login, submit : true});

    fetch('https://oneclickhuman.com/oneclickprice/register', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : user.email,
        'password' : user.password
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         //console.log(json);
         if(json.id === 0){
                     setLogin({...login, user_exist : true}); 
                     setToggle('switch to login'); 
         }else{
            setLogin({...login, account : 'pending', userID : json.id, user_email: json.user_email}); 
         }
    });
 };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    
    //console.log(user);
    if(user.email === '' && user.password === ''){
      alert('Please enter email and password');
            return;
    }
    setLogin({...login, user_exist : false}); 
    setLogin({...login, submit : true, invalid: false, verification: false});

    fetch('https://oneclickhuman.com/oneclickprice/login', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : user.email,
        'password' : user.password
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
        // console.log(json);
         if(json.login === 'success'){
             setLogin({...login, account : 'active', userID : json.id, user_email: json.user_email, invalid: false});
             localStorage.setItem('user', JSON.stringify({
                account : 'active',
                userID: json.id,
                user_email: json.user_email
              }));
             setTimeout(() => {
              setShow(false);
              window.location.reload('/affiliate'); 
             }, 500);

         }else if(json.login === 'on-verification'){
          setLogin({...login, verification : true});
         }else{
           setLogin({...login, invalid : true});
         }
    });
 };

const signOut = (e) => {
  e.preventDefault(); 
  setLogin({...login, submit: false, status : false, invalid: false, account: '', userID: 0});
  localStorage.removeItem('user');

    var toast = document.getElementById("toast-logout");
    var progress = document.getElementById("progress-logout");
    toast.classList.add("active");
    progress.classList.add("active");
    setTimeout(() =>{
       toast.classList.remove("active");
       window.location.reload('/affiliate');
    }, 1000);
}
const forgotPassword = () => {
   setForgotPass(true);
   setToggle("");
}
const sendOTP = (e) => {
   e.preventDefault();

   if(forgot_pass_email === ''){
       alert('Please enter your email');
       return;
   }
   setForgotPassSubmit(true);
   fetch('https://oneclickhuman.com/oneclickprice/forgot_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setForgotPassSubmit(false);
                   setForgotPass(false);
                   setResetPass(true);
              }
    });
}
const handleResetPassword = (e) => {
   e.preventDefault();
   if(resetPassOTP === ''){
       alert('Please Enter OTP');
       return;
   }  
   if(resetPassNew === ''){
       alert('Please Enter new password');
       return;
   }
   if(resetPassConfirm === ''){
       alert('Please Enter confirm password');
       return;
   }
   if(resetPassNew !== resetPassConfirm){
       alert('New Password and Confirm Password does not match!');
       return;
   }

   setResetPassSubmit(true);
   setResetPassFailure(false);

      fetch('https://oneclickhuman.com/oneclickprice/reset_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email,
        'new_password' : resetPassNew,
        'otp' : resetPassOTP
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setResetPassSuccess(true);
                   setResetPassSubmit(false);
              }else{
                 setResetPassSubmit(false);
                 setResetPassFailure(true);
              }
    });  
}
const userClick = (e) => {
  e.preventDefault();
   if(login.account !== 'active'){
      setShow(true);
  }else{
    navigate("/account");
  }
}
const resendEmailVerification = () => {
   //console.log(login.userID);
   fetch('https://oneclickhuman.com/oneclickprice/resend_email_verification', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : login.user_email,
        'user_id' : login.userID,
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         //console.log(json);
         if(json.status === 'success'){
              alert('Verification Email is resent.');
         }
      });
}

useEffect(() => {
     if(login.account === 'pending'){
       const sse = new EventSource(`https://oneclickhuman.com/oneclickprice/sse/${login.userID}`);
       sse.onmessage = function (event) {
        //console.log(event.data);
        if(event.data == 1){
          setLogin({...login, account : 'active'});
          localStorage.setItem('user', JSON.stringify({
            account : 'active',
            userID: login.userID,
            user_email: login.user_email
            }));
            setTimeout(() => {
            setShow(false);
          }, 2000);
               
          this.close();
        }    
       };
   }
}, [login])



const ChangeCouponCode = () => {
  console.log('d');
}


 return(
       <>
         <Helmet>
           <title>Affiliate Terms - OneClickHuman</title>
           <meta name="description" content="" />
         </Helmet>

         <div id="body" className={style}>
{ matches === true ?
  <div className="colors">
    <span className="active" onClick={() => changeColor('#1dd1a1')} style={{backgroundColor: '#1dd1a1'}}></span>
    <span onClick={() => changeColor('#ff6b6b')} style={{backgroundColor: '#ff6b6b'}}></span>
    <span onClick={() => changeColor('#2e86de')} style={{backgroundColor: '#2e86de'}}></span>
    <span onClick={() => changeColor('#f368e0')} style={{backgroundColor: '#f368e0'}}></span>
    <span onClick={() => changeColor('#ff9f43')} style={{backgroundColor: '#ff9f43'}}></span>
  </div>
  : '' }

{ matches !== true ?
  <>
    <h1 id="mob-h1"><span className="realistic-marker-highlight">AI to Human Content Converter</span></h1>
    <svg xmlns="www.w3.org/2000/svg" version="1.1" className="svg-filters" style={{display: 'none'}}>
     <defs>
       <filter id="marker-shape">
        <feTurbulence type="fractalNoise" baseFrequency="0 0.15" numOctaves="1" result="warp" />
        <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="30" in="SourceGraphic" in2="warp" />
       </filter>
     </defs>
    </svg>
    </>
: ''}

   <AccountBox id={ matches === true ? "": "tool-off" }>
        <Heading>
          <InfoIcon/> <b>Affiliate Terms</b>
        </Heading>
     { user_info ? 
        <Content>
          <div className="innerAcc">
            <Row>
              <div className="column-inner sidebar" style={{width: '300px', padding: '0'}}>
                <ul>
                  <li><a href="/affiliate">Affiliate</a></li>
                  <li><a href="/payouts">Payouts</a></li>
                  <li><a href="/affiliate-terms" className="active">Affiliate Terms</a></li>
                </ul>          
              </div> 
              <div className="column-inner" style={{ paddingLeft: '40px'}}>
                <Card>
                  <Row>
                    <div className="mycol-1">
                      <span className="my-value">15%</span><br></br>
                      <span className="badge" style={{background: '#f2a841'}}>Commission Rate</span>
                    </div>
                    <div className="mycol-1">
                      <span className="my-value">$150</span><br></br>
                      <span className="badge" style={{background: '#888888'}}>Payout Threshold</span>
                    </div>
                  </Row>
                </Card> 
                <Card>
                  <h3>Commission Rate</h3>
                  <p>You will get 15 % commission of each full price payment made by a referred user. In a case of a discounted price, the commission will be reduced by the percentage amount of the discount.</p>
                </Card> 
                <Card>
                  <h3>Payouts</h3>
                  <p>Commission payments are made via PayPal. You can request a payout when you reach a <b>minimum threshold of $150</b> with atleast <b>2 unique users</b>.</p>
             
                </Card> 
              </div>  
            </Row>
          </div>
        </Content>
        : 
        <Content>
          <div className="innerAcc">
           <p>Please <span style={{textDecoration: 'underline', color: '#03a9f4', cursor: 'pointer'}} onClick={() => {setShow(true); setToggle('switch to login');}}>login</span> to see this page</p>
          </div>
        </Content>
      }

   </AccountBox>

   { show === true ?
     <div id="popup">
        <div id="login-form">
          { toggle === 'switch to register' ?
            <form onSubmit={handleSubmit}>
               <h3 style={{marginBottom: '7px'}}>Create New Account!</h3>
               <p className="already">Already have an account? <span onClick={() => setToggle('switch to login')}>Login in</span></p>
                  <span className="closeLogin" onClick={() => {setLogin({...login, submit: false, status : false, invalid: false, verification: false, account : '', userID : 0, user_email: '', user_exist : false, user_exist_text : 'Email already exists'}); setShow(false); }}><CloseIcon/></span> 
              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                 <input type="password" placeholder="Password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>

              <div className="inputBox">
                 <input type="password" placeholder="Confirm password" onChange={(e) => setUser({ ...user, cnf_password: e.target.value })} />
              </div>

              <button type="submit">SIGN UP</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : ''
              }
              { login.account === 'pending' ?
               <>
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />awaiting email verification....</p>
                 <p id="resend-verification" style={{color: '#fff'}}><span onClick={resendEmailVerification}>Resend email verification</span></p>
                </>
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Account activated</p> 
                : ''
              }
              <div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => setToggle('switch to login')}><LockIcon/> Login</span> 
              </div>
            </form>
            : 
           ''}

               { toggle === 'switch to login' ?
             <form onSubmit={handleSubmitLogin}>
              <h3>Welcome Back!</h3>
                            <span className="closeLogin" onClick={() => setShow(false)}><CloseIcon/></span> 

              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                 <input type="password" placeholder="Enter your password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>
              <div id="forgot-pass">
                <span onClick={forgotPassword}>Forgot Password</span> 
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>LOGIN</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : ''
              }
              { login.user_exist === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>{login.user_exist_text}</p> 
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Login Success</p> 
                : ''
              }
              { login.invalid === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Invalid username or password</p> 
                : ''
              }
              { login.verification === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Email verification is pending</p> 
                : ''
              }
              <div className="form-footer">
                              <span className="left-foot">All fields are required.</span>
                              <span className="right-foot" onClick={() => setToggle('switch to register')}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
            : ''
          }
          { forgotPass === true ?
            <form onSubmit={sendOTP}>
              <h3>Forgot Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>Submit your email id, we will send an otp for resetting new password.</p>
              <span className="closeLogin" onClick={() => {setShow(false);setForgotPass(false)}}><CloseIcon/></span>
              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setForgotPassEmail(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SEND OTP</button>
              { forgot_pass_submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Sending OTP....</p> 
                : '' 
              }
              <div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => {setToggle('switch to register');setForgotPass(false)}}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
                       : ''
          }

          { resetPass === true ?
                       <form onSubmit={handleResetPassword}>
              <h3>Reset Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>We have sent an OTP to your email id, Please enter otp and set new password</p>
                            <span className="closeLogin" onClick={() => {setShow(false);setResetPass(false)}}><CloseIcon/></span>
              <div className="inputBox">
                 <input type="text" placeholder="Enter OTP" onChange={(e) => setResetPassOTP(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="password" placeholder="Enter New Password" onChange={(e) => setResetPassNew(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="text" placeholder="Confirm New Password" onChange={(e) => setResetPassConfirm(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SUBMIT</button>
              { reset_pass_submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : '' 
              }
              { resetPassSuccess === true ?
                <p style={{color: '#5bed61', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>New Password set Successfully, <span style={{cursor: 'pointer', }} onClick={() => {setResetPass(false);setToggle('switch to login'); }}>Login</span></p>  
                : ''  
              }
              { resetPassFailure === true ?
                <p style={{color: 'red', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>Invalid OTP, try again</p>    
                : ''
              }
            </form> 
            : ''
          }
        </div>
     </div>
      : ''
}


  <div className={ matches === true ? 'navigation' : 'navigation navigation-mob' }>
    <ul>
      <li>
        <a href="/">
          <span className="icon"><img src={site} style={{width: '36px'}}/></span>
          <span className="title"><h2>OneClickHuman</h2></span>
        </a>
      </li>
      <li>
        <a href="/">
          <span className="icon"><ion-icon name="home-outline"></ion-icon></span>
          <span className="title">Home</span>
        </a>
      </li>
      <li>
        <a href="#" onClick={userClick}>
          <span className="icon"><ion-icon name="person-outline"></ion-icon></span>
          <span className="title">{ login.account === 'active' ? login.user_email : 'User'}</span>
        </a>
      </li>
      <li>
        <a href="#" onClick={() => navigate("/pricing")}>
          <span className="icon"><AttachMoneyIcon style={{fontSize: '1.75em', color : '#213e6b'}}/></span>
          <span className="title">Pricing</span>
        </a>
      </li>
      <li>
        <a href="#" onClick={() => navigate("/help")}>
          <span className="icon"><HelpOutlineIcon style={{fontSize: '1.75em', color : '#213e6b'}}/></span>
          <span className="title">Help</span>
        </a>
      </li>
      <li>
        <a href="/blog">
          <span className="icon"><ArticleOutlinedIcon style={{fontSize: '1.75em', color : '#213e6b'}}/></span>
          <span className="title">Blog</span>
        </a>
      </li>
      <li>
        <a href="/developer/api">
          <span className="icon"><CodeIcon style={{fontSize: '1.75em', color : '#213e6b'}}/></span>
          <span className="title">API</span>
        </a>
      </li>
      <li>
        { login.account === 'active' ? 
        <a href="#" onClick={signOut}>
          <span className="icon"><ion-icon name="log-out-outline"></ion-icon></span>
          <span className="title">Sign Out</span>
        </a>
        :
        <a href="#" onClick={() => { setShow(true); setToggle('switch to login');}}>
          <span className="icon"><LoginIcon/></span>
          <span className="title">Sign In</span>
        </a>
         }
      </li>
    </ul>
    <div className="toggle" onClick={toggleMenu}></div>
  </div>
</div>

{ matches !== true ?
  <div id="for-mobile">
    <p>Our Mobile experience is still under development, but you can get the best experience by using our desktop version</p>
  </div>
  : ''
}

{ matches === true ?
  <div className="toast" id="toast-logout">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Logged Out</span>
      </div>
    </div>
    <div className="progress" id="progress-logout"></div>
  </div>
    : ''
}  
 
       </>
    )
}

export default AffiliateTerms;

const Progress = styled.div`
   width:100%;
   margin-bottom: 15px;

   .progress-top {
      display: flex;
      margin-bottom: 5px;

      p {
         width: 100%;
      }
      .progress-label {
         font-size: 14px;
      }
      .progress-value {
        text-align: right;
        font-size: 13px;
      }
    }

    .progress-btm {
      font-size: 12px;
      text-align: left;
      margin-top: 4px;
      color: #787878;
      b {
          font-size: 25px;
          color: #000;
      }
     }
`;

const SmallBtn = styled.div`
   display: flex;
   width:100%;

   button {
     position: static !important;
     margin-right: 10px;
     width: unset !important;
     height: unset !important;
     padding: 8px 12px;
     font-size: 13px; 
   }
`;

const Table = styled.div`
   padding-left: 15px;
   table.my-table {
    border: 1px solid #ddd;
    border-collapse: collapse;
    background: #fff;
    width: 100%;
   }
   th, td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: center;
   }
   th {
    color: #fff;
    background: #1d93f0;
}
`;

const Row = styled.div`
   width: 100%;
   display: flex;

   .mycol-1 {
    width: 50%;
    border-right: 1px solid #e3e0e0;
    text-align: center;
    height: 68px;
   }
   .mycol-1:last-child {
      border-right: 0;
   }

   .sidebar {
      ul {
        list-style: none;
        background: #fff;
        height: 418px;
        position: fixed;
        width: 238px;
        a {
          display: block;
          padding: 16px 20px;
          text-decoration: none;
          border-bottom: 1px solid #ececec;
          color: #666666;
          font-weight: 500;
        }
        a.active {
          border-left: 3px solid #1dd1a1;
          color: #000;
        }
      }
   }

   .column-inner {
      width: 100%;
      padding: 0 15px;

      .note {
         font-size: 12px;
         background: #e7f4fd;
         color: #0f08ad;
         font-weight: 500;
         line-height: 20px;
         padding: 10px;
      }
   }

   h2 {
    svg {color: #365fa1;}
   }

   #keep-in-mind {
      background: #fff1ed;
      padding: 25px;
      font-size: 14px;
      border-radius: 5px;
      margin-bottom: 40px;
      b {
          display: block;
          margin-bottom: 10px;
          font-size: 16px;
          svg {
            vertical-align: sub;
            margin-right: 5px;
            color: #f28141;
          }
       }
   }
`;

const Card = styled.div`
   width:98%;
   padding: 30px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 1px 1px 13px 1px #b8b5b5;
   position: relative;
   text-align: left !important;
   margin: 10px;
   margin-bottom: 50px;

   span.my-value {
    font-size: 20px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 5px;
   }
   span.badge {
    font-size: 11px;
    padding: 5px 13px;
    color: #fff;
    border-radius: 4px;
    background: #000;
   }

   h3 {margin-bottom: 12px;}
   p {line-height: 28px;}

   p#coupon-code {
     margin-top: 12px;
      b {
        color: #f28141;
      }
   }

   .plan-card {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 20px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 50px;
        font-weight: 600;
        vertical-align: text-top;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
    position: static;
    width: 150px;

     svg { vertical-align: middle; }
   }
   button:hover {
     background: #1f84d4;
   }
`;

const CardPopup = styled.div`
      width:100%;
   padding: 20px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 1px 1px 13px 1px #b8b5b5;
   position: relative;
   text-align: left !important;
   margin: 10px;

   .features {
    font-size: 14px;
    line-height: 27px;
    color: #000;
   }

   .plan-card {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 20px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 50px;
        font-weight: 600;
        vertical-align: text-top;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 145px;
    height: 40px;
    cursor: pointer;

     svg { vertical-align: middle; }
   }
`;

const Heading = styled.div`
   width:100%;
   padding: 20px 15px;
   background: #fff;
   font-size: 18px;
   position: relative;
   svg { 
    vertical-align: sub;
    color: #2196f3;
    font-size: 25px; }
    a {
      text-decoration: none;
    font-size: 15px;
    padding: 8px 16px;
    background: #0abc8d;
    color: #fff;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    top: 12px;
    }
`;

const AccountBox = styled.div`
    width: 80%;
    margin: 0 auto;
    background: #fbfbfb;
    border-bottom: 1px solid #ddd;
    padding: 0;
    box-shadow: 15px 15px rgb(0 0 0 / 10%);
    position: relative;
    height: 545px;
`;

const Content = styled.div`
   width:100%;
   overflow-x: hidden;

   .innerAcc {
      width: 103%;
      overflow-y: scroll;
      height: 450px;
       padding: 30px;
   }
`;

